import {Link} from "react-router-dom";

const Error404 = props => {
    return <div className={'page page-error'}>
        <h1>Ой...</h1>
        <p>Извините, искомая страница не найдена.</p>

        <Link to={'/'}>Вернуться на главную страницу</Link>
    </div>
}

export default Error404;
