import {useEffect, useState} from "react";
import {Button, Modal, InputGroup, Form, Table, Spinner} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {domain} from "../../tools/parameters";

const AccountList = props => {
    const [accounts, setAccounts] = useState(undefined);
    const [phone, setPhone] = useState('');
    const [phoneIsSend, setPhoneIsSend] = useState(false);
    const [code, setCode] = useState('');
    const [codeIsSend, setCodeIsSend] = useState(false);
    const [password, setPassword] = useState('');
    const [showTgAuthModal, setShowTgAuthModal] = useState(false);

    useEffect(() => {
        getAccounts()
    }, [])

    const getAccounts = () => {
        fetch(domain + '/api/chat/user')
            .then(response => response.json())
            .then(response => {
                console.log('Data: ', response)
                if (!response.data) setAccounts(null);
                else setAccounts(response.data);
            })
            .catch(error => {
                setAccounts(null)
                console.error('request https://сервер.телеграм.демо-стенд.рф/api/chat/user', error)
            })
    }

    const handleTelegramLogin = () => {
        let fd = new FormData();

        if (phone.replace(/^\D+/g, '').length === 11) fd.append('phone', '+' + phone.replace(/^\D+/g, ''))
        else {
            alert('Номер телефона введен некорректно');
            return;
        }
        if (phoneIsSend && code.length > 3) fd.append('code', code)
        if (codeIsSend && password.length > 3) fd.append('password', password)

        fetch(domain + '/api/chat/auth', {body: fd, method: 'POST',})
            .then(response => response.json())
            .then(response => {
                if (!phoneIsSend) {
                    setPhoneIsSend(true)
                }
                else if (!codeIsSend && response.result['_'] === 'account.password') {
                    setCodeIsSend(true);
                }
                else {
                    setPhoneIsSend(false);
                    setCodeIsSend(false);
                    setShowTgAuthModal(false);
                    setPassword('');
                    setCode('');
                    setPhone('');
                    getAccounts();
                }
            })
            .catch(error => {
                console.error(error)
            })
    }

    const logout = (phone) => {
        let fd = new FormData();
        fd.append('phone', '+' + phone)

        fetch(domain + '/api/chat/logout', {method: 'post', body: fd,})
            .then(response => response.json())
            .then(data => {
                getAccounts();
            })
            .catch(error => {
                console.error('request https://сервер.телеграм.демо-стенд.рф/api/chat/user', error)
            })
    }

    return <div className={'page'}>
        <h1 className={'mb-4'}>Аккаунты соц. сетей</h1>
        <div className={'d-flex flex-row align-items-center justify-content-center flex-wrap mb-4'}>
            <Button variant="primary" onClick={() => setShowTgAuthModal(true)} className={'mb-2'}>Добавить аккаунт
                Telegram <FontAwesomeIcon icon="fa-brands fa-telegram" /></Button>
            <Button variant="primary" onClick={() => alert('Функционал в разработке')} className={'mx-2 mb-2'}>Добавить
                аккаунт ВКонтакте <FontAwesomeIcon icon="fa-brands fa-vk" /></Button>
            <Button variant="primary" onClick={() => alert('Функционал в разработке')} className={'mb-2'}>Добавить
                аккаунт WhatsApp <FontAwesomeIcon icon="fa-brands fa-whatsapp" /></Button>
        </div>

        {accounts === undefined ? <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
        </Spinner> : accounts && <Table striped bordered hover>
            <thead>
            <tr>
                <th>#</th>
                <th>Username</th>
                <th>Соц. сеть</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {accounts.map(account => {
                if (!account) return null
               return <tr key={account.id}>
                   <td>1</td>
                   <td>@{account.username} (+{account.phone})</td>
                   <td>
                       <FontAwesomeIcon icon="fa-brands fa-telegram" />
                   </td>
                   <td>
                       <Button variant={'outline-danger'} onClick={() => logout(account.phone)}>
                           <FontAwesomeIcon icon="fa-solid fa-door-open" />
                       </Button>
                   </td>
               </tr>
            })}
            </tbody>
        </Table>}

        <p className={'subtext'}>{domain}</p>

        <Modal show={showTgAuthModal} onHide={() => setShowTgAuthModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Вход в аккаунт Телеграм</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <InputGroup className="mb-3">
                    <Form.Control
                        placeholder="Телефон: +7 (ХХХ) ХХХ-ХХ-ХХ"
                        aria-label="Телефон: +7 (ХХХ) ХХХ-ХХ-ХХ"
                        aria-describedby="basic-addon1"
                        value={phone}
                        onChange={e => setPhone(e.target.value)}
                        onFocus={() => {
                            if (phone.length < 2) setPhone('+7')
                        }}
                    />
                    {!phoneIsSend &&
                        <Button variant="outline-secondary" id="button-addon1" onClick={() => handleTelegramLogin()}>
                            Отправить
                        </Button>}
                </InputGroup>

                {phoneIsSend && <InputGroup className="mb-3">
                    <Form.Control
                        placeholder="Код: ХХХХХ"
                        aria-label="Код: ХХХХХ"
                        aria-describedby="basic-addon2"
                        value={code}
                        onChange={e => setCode(e.target.value)}
                    />
                    {!codeIsSend &&<Button variant="outline-secondary" id="button-addon2" onClick={() => handleTelegramLogin()}>
                        Отправить
                    </Button>}
                </InputGroup>}

                {codeIsSend && <InputGroup className="mb-3">
                    <Form.Control
                        placeholder="Пар*ль"
                        aria-label="Пар*ль"
                        type={'password'}
                        aria-describedby="basic-addon3"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                    <Button variant="outline-secondary" id="button-addon3" onClick={() => handleTelegramLogin()}>
                        Отправить
                    </Button>
                </InputGroup>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowTgAuthModal(false)}>
                    Закрыть
                </Button>
            </Modal.Footer>
        </Modal>
    </div>
}

export default AccountList;
