import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {useLocation, useNavigate} from "react-router-dom";

const Header = props => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleNavigate = (e, path) => {
        e.preventDefault();
        navigate(path);
    }

    return <Navbar expand="lg" className="bg-body-tertiary" bg="dark" data-bs-theme="dark" fixed="top">
        <Container>
            <Navbar.Brand href="#home">Chat Searcher</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    <Nav.Link active={location.pathname === '/account'} href="/account" onClick={(e) => handleNavigate(e, '/account')}>Аккаунты</Nav.Link>
                    <Nav.Link active={location.pathname === '/search'} href="/search" onClick={(e) => handleNavigate(e, '/search')}>Поиск</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>
}

export default Header;
