import {useEffect, useState} from "react";
import {Button, InputGroup, Form, Spinner, Table, Accordion, Modal, ButtonGroup, Pagination} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {domain} from "../../tools/parameters";
import {removeEmoji} from "../../tools/stringManager";

const SearchPage = props => {
    const [searchString, setSearchString] = useState('');
    const [account, setAccount] = useState(undefined);
    const [dialogIds, setDialogIds] = useState([]);
    const [dialogs, setDialogs] = useState({});
    const [filterDialogs, setFilterDialogs] = useState([]);
    const [results, setResults] = useState(null);
    const [count, setCount] = useState(null);
    const [page, setPage] = useState(0);
    const [showFilters, setShowFilters] = useState(false);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [appliedFilter, setAppliedFilter] = useState({});
    const [showDialogs, setShowDialogs] = useState(true);
    const [showChats, setShowChats] = useState(true);
    const [showChannels, setShowChannels] = useState(true);
    const [disableDialogs, setDisableDialogs] = useState({});
    const [disableChats, setDisableChats] = useState({});
    const [disableChannels, setDisableChannels] = useState({});
    const [disableMessages, setDisableMessages] = useState({});
    const [isLoaded, setIsLoaded] = useState(true);

    useEffect(() => {
        getAccount();
    }, [])
    useEffect(() => {
        if (searchString.length > 0) handleSearch();
    }, [appliedFilter, page])
    useEffect(() => {
        // dialogIds.forEach((id, key) => {
        //     setTimeout(() => getDialog(id), key * 500);
        // })
    }, [dialogIds])

    const getAccount = () => {
        fetch(domain + '/api/chat/user')
            .then(response => response.json())
            .then(response => {
                if (!response.data) setAccount(null);
                else {
                    setAccount(response.data);
                    getDialogs();
                }
            })
            .catch(error => {
                setAccount(null)
                console.error('request https://сервер.телеграм.демо-стенд.рф/api/chat/user', error)
            })
    }

    const getDialogs = () => {
        fetch(domain + '/api/chat/dialogs')
            .then(response => response.json())
            .then(response => {
                setDialogIds(response.data);
                let tmp = {};
                response.data.forEach(id => {
                    tmp[id] = {};
                })
                setDialogs(tmp)
            })
            .catch(error => {
                console.error('request https://сервер.телеграм.демо-стенд.рф/api/chat/dialogs', error)
            })
    }

    const getDialog = (id) => {
        if (Object.keys(dialogs[id]).length === 0) fetch(domain + '/api/chat/dialogs/' + encodeURI(id))
            .then(response => response.json())
            .then(response => {
                let tmp = Object.assign({}, dialogs);
                tmp[id] = response.data;
                setDialogs(tmp)
            })
            .catch(error => {
                console.error('request https://сервер.телеграм.демо-стенд.рф/api/chat/dialogs', error)
            })
    }

    const handleUpload = () => {
        let leads = [];

        results.messages.forEach((message, key) => {
            let user = results.users.find(item => item.id === message.peer_id.user_id);
            if (!user) user = results.users.find(item => item.id === message?.from_id?.user_id);
            if (!user) user = results.users.find(item => item.id === message?.from_id);
            let chat = results.chats.find(item => item.id === message.peer_id.chat_id);
            let channel = results.chats.find(item => item.id === message.peer_id.channel_id);
            if (!channel) channel = results.chats.find(item => item.id === message.peer_id);

            let skip = false;
            if (!showDialogs && user && !chat) skip = true;
            if (!showChats && user && chat) skip = true;
            if (!showChannels && channel) skip = true;

            if (user && !chat && disableDialogs[user.id]) skip = true;
            if (user && chat && disableChats[chat.id]) skip = true;
            if (channel && disableChannels[channel.id]) skip = true;

            if (!skip && !disableMessages[message.id] && user && (user.username || (channel && channel.username && message.id))) leads.push({
                username: user ? user.username : null,
                message: 'Запрос: ' + searchString,
                messageLink: channel ? 'https://t.me/' + channel.username + '/' + message.id : null,
                chat: chat ? chat.title : null,
                channel: channel ? 'https://t.me/' + channel.username : null,
            });
        })

        fetch(domain + '/api/upload/', {method: 'POST', body: JSON.stringify(leads),})
            .then(response => response.json())
            .then(response => {
                alert('Уже существовало: ' + response.data.old + ' лидов. Новых лидов: ' + response.data.new);
            })
            .catch(error => {
                console.error('request https://сервер.телеграм.демо-стенд.рф/api/upload/', error)
            })
    }

    const handleSearch = () => {
        console.log(appliedFilter)
        setIsLoaded(false);
        let from = null, to = null, month = null, date = null;
        if (appliedFilter.from) {
            month = appliedFilter.from.getMonth() + 1;
            if (month > 12) month = 1;
            if (month.toString().length === 1) month = '0' + month.toString();

            date = appliedFilter.from.getDate();
            if (date.toString().length === 1) date = '0' + date.toString();

            from = appliedFilter.from.getFullYear() + '-' + month + '-' + date + 'T00:00:00';
        }
        if (appliedFilter.to) {
            month = appliedFilter.to.getMonth() + 1;
            if (month > 12) month = 1;
            if (month.toString().length === 1) month = '0' + month.toString();

            date = appliedFilter.to.getDate();
            if (date.toString().length === 1) date = '0' + date.toString();

            to = appliedFilter.to.getFullYear() + '-' + month + '-' + date + 'T23:59:59';
        }

        fetch(domain + `/api/chat/search?${results && results.next_rate ? 'page=' + results.next_rate + '&' : ''}search=` + encodeURI(searchString) +
            (from ? '&from=' + encodeURI(from) : '') + (to ? '&to=' + encodeURI(to) : ''))
            .then(response => response.json())
            .then(response => {
                setResults(response.data)
                setCount(response.data.count ? response.data.count : response.data.messages.length)
                setShowDialogs(true)
                setShowChannels(true)
                setShowChats(true)
                setIsLoaded(true);
            })
            .catch(error => {
                console.error('request https://сервер.телеграм.демо-стенд.рф/api/chat/user', error)
                setIsLoaded(true);
            })
    }

    const paginationRender = () => {
        let items = [];
        items.push(<Pagination.Next disabled={page >= Math.ceil(count / 20) - 1} onClick={() => setPage(page + 1)}/>);

        return <Pagination size="sm">{items}</Pagination>
    }

    return <div className={'page'}>
        <h1 className={'mb-4'}>Поиск в сообщениях</h1>

        {account === undefined && <div className={'d-flex align-items-center justify-content-center'}>
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>}
        {account !== undefined && !account &&
            <div className={'d-flex flex-column align-items-center justify-content-center'}>
                <p>Невозможно искать сообщения, пока Вы не подключили ни один аккаунт!</p>
                <Link to={'/account'}>Перейти на страницу управления аккаунтами</Link>
            </div>}

        {account && <div>
            <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">Поиск:</InputGroup.Text>
                <Form.Control
                    placeholder="Сообщение для поиска"
                    aria-label="Сообщение для поиска"
                    aria-describedby="basic-addon1"
                    value={searchString}
                    onChange={e => setSearchString(e.target.value)}
                />
                <Button variant="outline-secondary" id="button-addon2" onClick={() => handleSearch()}>
                    <FontAwesomeIcon icon="fa-solid fa-magnifying-glass"/>
                </Button>
            </InputGroup>
            <div className={'d-flex flex-row align-items-start justify-content-between mb-2'}>
                <p>{Object.keys(appliedFilter).length > 0 ? 'Поиск совершается ' : ''} {appliedFilter.from ? 'c ' + appliedFilter.from.getDate() + '.' +
                    (appliedFilter.from.getMonth() + 1 > 12 ? 1 : appliedFilter.from.getMonth() + 1) + '.' +
                    appliedFilter.from.getFullYear() + ' ' : ''} {appliedFilter.to ? 'по ' + appliedFilter.to.getDate() + '.' +
                    (appliedFilter.to.getMonth() + 1 > 12 ? 1 : appliedFilter.to.getMonth() + 1) + '.' +
                    appliedFilter.to.getFullYear() + ' ' : ''}</p>
                <div>
                    <Button className={'mx-2'} variant="outline-primary" id="button-addon2"
                            onClick={() => setShowFilters(true)}>
                        <FontAwesomeIcon icon="fa-solid fa-filter"/>
                    </Button>
                    <Button variant="outline-success" id="button-addon3" onClick={() => handleUpload()}>
                        <FontAwesomeIcon icon="fa-solid fa-floppy-disk"/>
                    </Button>
                </div>
            </div>
            <hr/>

            {isLoaded && results && <Accordion className={'mb-3'}>
                {/*<Accordion.Item eventKey="0">*/}
                {/*    <Accordion.Header>*/}
                {/*        <Form.Check*/}
                {/*            type={'checkbox'}*/}
                {/*            id={`default-checkbox`}*/}
                {/*            label={`Диалоги`}*/}
                {/*            checked={showDialogs}*/}
                {/*            onClick={e => {*/}
                {/*                e.preventDefault();*/}
                {/*                e.stopPropagation();*/}
                {/*                setShowDialogs(!showDialogs)*/}
                {/*            }}*/}
                {/*        />*/}
                {/*    </Accordion.Header>*/}
                {/*    <Accordion.Body>*/}
                {/*        <div className={'d-flex flex-row align-items-center justify-content-start'}>*/}
                {/*            {results.messages.map((message, key) => {*/}
                {/*                let user = results.users.find(item => item.id === message.peer_id.user_id);*/}

                {/*                if (!user) return null;*/}
                {/*                return <Form.Check key={key}*/}
                {/*                                   className={'mx-2'}*/}
                {/*                                   type={'checkbox'}*/}
                {/*                                   id={`default-checkbox`}*/}
                {/*                                   label={'@' + user.username + ' (' + user.first_name + ')'}*/}
                {/*                                   checked={!disableDialogs[user.id]}*/}
                {/*                                   onClick={e => {*/}
                {/*                                       let tmp = Object.assign({}, disableDialogs),*/}
                {/*                                           array = Object.keys(disableDialogs);*/}
                {/*                                       if (array.find(item => item.toString() === user.id.toString())) delete tmp[user.id];*/}
                {/*                                       else tmp[user.id] = true;*/}
                {/*                                       setDisableDialogs(tmp);*/}
                {/*                                   }}*/}
                {/*                />*/}
                {/*            })}*/}
                {/*        </div>*/}
                {/*    </Accordion.Body>*/}
                {/*</Accordion.Item>*/}
                {/*<Accordion.Item eventKey="1">*/}
                {/*    <Accordion.Header>*/}
                {/*        <Form.Check*/}
                {/*            type={'checkbox'}*/}
                {/*            id={`default-checkbox`}*/}
                {/*            label={`Чаты`}*/}
                {/*            checked={showChats}*/}
                {/*            onClick={e => {*/}
                {/*                e.preventDefault();*/}
                {/*                e.stopPropagation();*/}
                {/*                setShowChats(!showChats)*/}
                {/*            }}*/}
                {/*        />*/}
                {/*    </Accordion.Header>*/}
                {/*    <Accordion.Body>*/}
                {/*        <div className={'d-flex flex-row align-items-center justify-content-start'}>*/}
                {/*            {results.messages.map((message, key) => {*/}
                {/*                let chat = results.chats.find(item => item.id === message.peer_id.chat_id);*/}

                {/*                if (!chat) return null;*/}
                {/*                return <Form.Check key={key}*/}
                {/*                                   className={'mx-2'}*/}
                {/*                                   type={'checkbox'}*/}
                {/*                                   id={`default-checkbox`}*/}
                {/*                                   label={chat.title}*/}
                {/*                                   checked={!disableChats[chat.id]}*/}
                {/*                                   onClick={e => {*/}
                {/*                                       let tmp = Object.assign({}, disableChats),*/}
                {/*                                           array = Object.keys(disableChats);*/}
                {/*                                       if (array.find(item => item.toString() === chat.id.toString())) delete tmp[chat.id];*/}
                {/*                                       else tmp[chat.id] = true;*/}
                {/*                                       setDisableChats(tmp);*/}
                {/*                                   }}*/}
                {/*                />*/}
                {/*            })}*/}
                {/*        </div>*/}
                {/*    </Accordion.Body>*/}
                {/*</Accordion.Item>*/}
                <Accordion.Item eventKey="2">
                    <Accordion.Header>
                        <Form.Check
                            type={'checkbox'}
                            id={`default-checkbox`}
                            label={`Каналы`}
                            checked={showChannels}
                            onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                setShowChannels(!showChannels)
                            }}
                        />
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className={'d-flex flex-row align-items-center justify-content-start flex-wrap'}>
                            {results.chats.map((channel, key) => {
                                // let channel = results.chats.find(item => item.id === message.peer_id.channel_id);
                                // if (!channel) channel = results.chats.find(item => item.id === message.peer_id);
                                //
                                // if (!channel) return null;
                                return <Form.Check key={key}
                                                   className={'mx-2'}
                                                   type={'checkbox'}
                                                   id={`default-checkbox`}
                                                   label={channel.title}
                                                   checked={!disableChannels[channel.id]}
                                                   onClick={e => {
                                                       let tmp = Object.assign({}, disableChannels),
                                                           array = Object.keys(disableChannels);
                                                       if (array.find(item => item.toString() === channel.id.toString())) delete tmp[channel.id];
                                                       else tmp[channel.id] = true;
                                                       setDisableChannels(tmp);
                                                   }}
                                />
                            })}
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>}

            {!isLoaded ? <div className={'d-flex align-items-center justify-content-center'}>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div> : results && <Table striped bordered hover>
                <thead>
                <tr>
                    <th>#</th>
                    <th>Пользователь или чат</th>
                    <th>Сообщение</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td colSpan={3}>
                        Сообщений
                        показано: {page * 19 + 1}-{count < 20 ? count + (page * 19) : (page + 1) * 19 + 1}/{count}
                    </td>
                </tr>
                {results.messages.map((message, key) => {
                    let messageHTML = (message.message ?? '').replace(searchString, '<b><i>' + searchString + '</i></b>');
                    let user = results.users.find(item => item.id === message.peer_id.user_id);
                    if (!user) user = results.users.find(item => item.id === message?.from_id?.user_id);
                    if (!user) user = results.users.find(item => item.id === message?.from_id);
                    let chat = results.chats.find(item => item.id === message.peer_id.chat_id);
                    let channel = results.chats.find(item => item.id === message.peer_id.channel_id);
                    if (!channel) channel = results.chats.find(item => item.id === message.peer_id);
                    let messageLink = channel ? 'https://t.me/' + channel.username + '/' + message.id : null;

                    // if (key + 1 + (page * 19) === 10) console.log(user)
                    if (!showDialogs && user && !chat) return null;
                    if (!showChats && user && chat) return null;
                    if (!showChannels && channel) return null;

                    if (user && !chat && disableDialogs[user.id]) return null;
                    if (user && chat && disableChats[chat.id]) return null;
                    if (channel && disableChannels[channel.id]) return null;
                    let date = new Date(message.date * 1000);

                    return <tr key={message.id}>
                        <td>
                            {key + 1 + (page * 19)}
                            <br/>
                            {date.getDate()}.{date.getMonth() + 1}.{date.getFullYear()}
                            <br/>
                            <br/>
                            <input type={'checkbox'} defaultChecked={true} onChange={e => {
                                let tmp = Object.assign({}, disableMessages);
                                if (e.target.checked) delete tmp[message.id];
                                else tmp[message.id] = message.id;
                                setDisableMessages(tmp)
                            }}/> <label>upload</label>
                        </td>
                        <td>
                            {user && <span>Пользователь: {user.username && user.username.length > 0 ?
                                <a href={'https://t.me/' + user.username} rel="noreferrer"
                                   target={'_blank'}>@{user.username}</a> : 'без юзернейма. ссылки нет.'}</span>}
                            {chat && <br/>}
                            {chat && 'Чат: ' + chat.title}
                            {channel && <br/>}
                            {channel && <span>{!user ? 'Канал' : 'Чат'}: <a href={'https://t.me/' + channel.username}
                                                                            rel="noreferrer"
                                                                            target={'_blank'}>{channel.title}</a></span>}
                            {messageLink && <br/>}
                            {messageLink && <br/>}
                            {messageLink && <a href={messageLink} target={'_blank'}>Сообщение</a>}
                        </td>
                        <td dangerouslySetInnerHTML={{__html: messageHTML}}/>
                    </tr>
                })}
                </tbody>
            </Table>}
            {count && count > 0 ? paginationRender() : null}
        </div>}

        <Modal show={showFilters} onHide={() => setShowFilters(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Фильтр для поиска</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>По дате:</p>
                <div className={'d-flex flex-row align-items-center justify-content-center flex-wrap mb-2'}>
                    <span className={'w-30px'}>С</span>
                    <DatePicker className={'mx-2 form-control'} dateFormat="dd.MM.yyyy" selected={dateFrom}
                                onChange={(date) => setDateFrom(date)} closeOnScroll={true}
                                placeholderText="Начать с даты..."/>
                </div>
                <div className={'d-flex flex-row align-items-center justify-content-center flex-wrap mb-2'}>
                    <span className={'w-30px'}>По</span>
                    <DatePicker className={'mx-2 form-control'} dateFormat="dd.MM.yyyy" selected={dateTo}
                                onChange={(date) => setDateTo(date)} closeOnScroll={true}
                                placeholderText="Закончить датой..."/>
                </div>
                <div className={'d-flex flex-row align-items-center justify-content-center flex-wrap mb-2'}>
                    <ButtonGroup aria-label="Date buttons">
                        <Button variant="outline-primary" onClick={() => {
                            setDateFrom(new Date());
                            setDateTo(new Date());
                        }}>Сегодня</Button>
                        <Button variant="outline-primary" onClick={() => {
                            let date = new Date();
                            date.setDate(date.getDate() - 1);

                            setDateFrom(date);
                            setDateTo(date);
                        }}>Вчера</Button>
                        <Button variant="outline-primary" onClick={() => {
                            let date = new Date();
                            date.setDate(date.getDate() - 7);

                            setDateFrom(date);
                            setDateTo(new Date());
                        }}>Неделя</Button>
                        <Button variant="outline-primary" onClick={() => {
                            let date = new Date();
                            date.setMonth(date.getMonth() - 1);

                            setDateFrom(date);
                            setDateTo(new Date());
                        }}>Месяц</Button>
                    </ButtonGroup>
                </div>
                {/*<p className={'mt-2'}>По чатам:</p>*/}
                {/*<div className={'d-flex flex-row flex-wrap align-items-center justify-content-start mh-250px'}>*/}
                {/*    {Object.keys(dialogs).map(dialogKey => {*/}
                {/*        return <Form.Check key={dialogKey}*/}
                {/*                           className={'mx-3 mb-2'}*/}
                {/*                           type={'checkbox'}*/}
                {/*                           id={`default-checkbox-` + dialogKey}*/}
                {/*                           label={'dialog: ' + dialogKey}*/}
                {/*                           checked={!!filterDialogs.find(item => item === dialogKey)}*/}
                {/*                           onClick={() => {*/}
                {/*                               let tmp = Array.from(filterDialogs);*/}
                {/*                               if (tmp.find(item => item === dialogKey)) delete tmp[tmp.findIndex(item => item === dialogKey)];*/}
                {/*                               else tmp.push(dialogKey)*/}
                {/*                               setFilterDialogs(tmp);*/}
                {/*                               console.log(dialogs[dialogKey]);*/}
                {/*                           }}*/}
                {/*        />*/}
                {/*    })}*/}
                {/*</div>*/}
            </Modal.Body>
            <Modal.Footer>
                <Button className={'mb-2'} variant="secondary" onClick={() => setShowFilters(false)}>
                    <FontAwesomeIcon icon="fa-solid fa-square-xmark"/> Отмена
                </Button>
                <Button className={'mx-2 mb-2'} variant="warning" onClick={() => {
                    setShowFilters(false)
                    setAppliedFilter({});
                    setDateTo(null);
                    setDateFrom(null);
                    setFilterDialogs([]);
                }}>
                    <FontAwesomeIcon icon="fa-solid fa-filter-circle-xmark"/> Сбросить
                </Button>
                <Button className={'mb-2'} variant="success" onClick={() => {
                    setShowFilters(false);
                    let filter = {};
                    if (dateFrom) filter.from = dateFrom;
                    if (dateTo) filter.to = dateTo;
                    if (filterDialogs.length > 0) filter.chats = filterDialogs;
                    setAppliedFilter(filter);
                }}>
                    <FontAwesomeIcon icon="fa-solid fa-filter"/> Применить
                </Button>
            </Modal.Footer>
        </Modal>
    </div>
}

export default SearchPage;
