import './App.css';
import {Navigate, useRoutes} from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faTelegram, faWhatsapp, faVk } from '@fortawesome/free-brands-svg-icons'
import Header from "./component/header";
import 'bootstrap/dist/css/bootstrap.min.css';
import AccountList from "./page/private/accountList";
import Error404 from "./page/Error404";
import SearchPage from "./page/private/search";

library.add(fas, faTelegram, faVk, faWhatsapp);

function App() {
    let routes = [
        {
            path: "/",
            children: [
                { index: true, element: <Navigate to="/account" replace={true} /> },
                {path: "/account", element: <AccountList />,},
                {path: "/search", element: <SearchPage />,},
                { path: "*", element: <Error404 /> },
            ],
        },
    ];
    let element = useRoutes(routes);

    return (
        <div className="App">
            <Header />
            <div className={'p-3'}>
                {element}
            </div>
        </div>
    );
}

export default App;
